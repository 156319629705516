

import { Col, Flex, Row } from "antd";
import "./Homepage.scss";
import styles from "./Homepage.module.scss"
import fileCheckPng from  "./asests/file-check-02@3x.png"
import bankPng from "./asests/bank@3x.png"
import fileSearchPng from "./asests/file-search-01@3x.png"
import cubePng from "./asests/cube-02@3x.png"
import Screen from "../components/Screen";
import Button from "../components/Button";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Link from "./components/Link";
import Icon from "../components/icon/Icon";
import { useNavigate } from "react-router-dom";
import adv1Png from  "./asests/adv/file-search-01.png"
import adv2Png from  "./asests/adv/OpenAiLogo.png"
import adv3Png from  "./asests/adv/users-01.png"
import adv4Png from  "./asests/adv/message-chat-square.png" 
import advPoster1Png from  "./asests/adv/pic1.png"
import advPoster2Png from  "./asests/adv/pic2.png"
import advPoster3Png from  "./asests/adv/pic3.png"
import advPoster4Png from  "./asests/adv/pic4.png" 


const advantages = [
    {
        icon: <Icon src={fileCheckPng} size={36} padding={0}></Icon>,
        title: "Easy Case Status Check",
        desc: "Quickly and conveniently check the status of your legal cases without hassle."
    },
    {
        icon: <Icon src={fileSearchPng} size={36} padding={0}></Icon>,
        title: "Case Research & Summary",
        desc: "Gather in-depth information and create concise summaries for your legal cases, making research efficient."
    },
    {
        icon: <Icon src={bankPng} size={36} padding={0}></Icon>,
        title: "Real-world Case Bank",
        desc: "Access a comprehensive repository of real-world legal cases, providing valuable insights and references for your work."
    },
    {
        icon: <Icon src={cubePng} size={36} padding={0}></Icon>,
        title: "Simplify Daily Work",
        desc: "Streamline your daily legal tasks, making your workload more manageable and efficient."
    }
]

const advantagesv2 = [
    {
        icon: <Icon src={adv1Png} size={20} padding={0}></Icon>,
        title: "Case Research & Analysis",
        desc: "Effortless tools to transform your publication, membership, awards, and salary data into compelling, citation-rich petition letters.",
        poster: <Icon src={advPoster1Png} size={616} height={288} padding={0}></Icon>,
    },
    {
        icon: <Icon src={adv2Png} size={20} padding={0}></Icon>,
        title: "End-to end AI Case Filing",
        desc: "Leazy.ai automates the entire EB1A/NIW process, from client intake to petition letter generation, streamlining every step along the way.",
        poster: <Icon src={advPoster2Png} size={616} height={288} padding={0}></Icon>,
    },
    {
        icon: <Icon src={adv3Png} size={20} padding={0}></Icon>,
        title: "Recommendation Letter Generation",
        desc: "We identify ideal recommenders and create tailored recommendation letters to support your case.",
        poster: <Icon src={advPoster3Png} size={616} height={288} padding={0}></Icon>,
    },
    {
        icon: <Icon src={adv4Png} size={20} padding={0}></Icon>,
        title: "Immigration Tailored Chatbot",
        desc: "A chatbot with an expert immigration database offers accurate, cited answers using AILA and AAO resources.",
        poster: <Icon src={advPoster4Png} size={616} height={288} padding={0}></Icon>,
    }
]




export default () => {

    const navigate = useNavigate()

    const gotoStart = () => {
        navigate("/workbench/chatbot")
    }
    

    return (
        <div className="homepage">
            <Header >
                <Link.Group>
                    <Link.Item onClick={() => navigate("/signup/first")}>Free trial</Link.Item>
                    <Link.Item onClick={() => navigate("/login") }>Log in</Link.Item>
                </Link.Group>
            </Header>
            <Screen>
                <Flex vertical gap={88} align="center" justify="center" className="homepage__banner">
                    <div className="homepage__banner__logo">
                        Leazy.ai: Where Law Meets <span className="homepage__banner__logo__highlight">Easy</span>
                    </div>
                    <Button.BlackBg  padding={[16, 28]} onClick={gotoStart}>Get Started</Button.BlackBg>
                </Flex>
            </Screen>
            <Flex vertical gap={64} className="homepage__capabilities">
                <Flex vertical gap={12} align="center">
                    <div className="homepage__capabilities__label">Our capabilities</div>
                    <Flex vertical gap={20} align="center">
                        <div className="homepage__capabilities__title">
                            We’re here to Simplify Your Legal Practice
                        </div>
                        <div className="homepage__capabilities__desc">
                            Discover how Leazy.AI can streamline your legal work. Check case statuses, research efficiently, access real-world cases, and make your daily legal tasks more manageable. We're here to empower your legal success.
                        </div>
                    </Flex>
                </Flex>
                {/* <Row gutter={32}>
                    {advantages.map(advatange => (
                        <Col span={24 / advantages.length}>
                            <Flex vertical gap={20} align="center" className="homepage__capabilities__advantage">
                                <Flex align="center" justify="center" className="homepage__capabilities__advantage__icon">
                                    {advatange.icon}
                                </Flex>
                                <Flex vertical gap={8} align="center">
                                    <div className="homepage__capabilities__advantage__title">{advatange.title}</div>
                                    <div className="homepage__capabilities__advantage__desc">{advatange.desc}</div>
                                </Flex>
                            </Flex>
                        </Col>
                    ))}
                </Row> */}
                <Row gutter={[48, 48]}>
                    {advantagesv2.map((adv, idx) => (
                        <Col span={12}>
                            <div className={styles['advantage']}>
                                <div className={styles['advantage__header']}>
                                    <div className={styles['advantage__icon']}>{adv.icon}</div>
                                    <div className={styles['advantage__title']}>{adv.title}</div>
                                    <div className={styles['advantage__desc']}>{adv.desc}</div>
                                </div>
                                <div className={[styles['advantage__bg'], styles[`advantage__bg_${idx}`]].join(" ")}>
                                    {/* {adv.poster} */}
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Flex>
            <Footer></Footer>
        </div>
    )
}