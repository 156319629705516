import axios from "axios"
import { checkCode, HttpResponse } from "../libs/http"



export interface UploadTokenGetRequest{
    name: string
    size: number
    content_type?: string
}

export interface UploadTokenDTO{
    file_id: string
    upload_args: {
        url: string
        fields: {[key: string]: string}
    }
}

export interface DownloadDTO{
    download_url: string
}

const getUploadToken = async (request: UploadTokenGetRequest) => {
    const res = (await axios.post(
        `/api/files`,
        request
    )).data as HttpResponse<UploadTokenDTO>
    checkCode(res)
    return res.data
}

export default {
    getUploadToken
}