import axios from "axios"
import { checkCode, HttpResponse } from "../libs/http"

export interface AssetDTO{
    item_id: string
    descriptions: AssetDescriptionDTO[]
    exhibits: ExhibitDTO[]
    status: AssetStatus
}

type AssetStatus = "NEEDED" | "COMPLETED" | "PENDING"
type LetterStatus = "SUCCEEDED" | "FAILED" | "UNSPECIFIED" | "SUBMITTED"

export interface AssetDescriptionDTO{
    title: string
    value: string
}

export interface ExhibitDTO{
    id: string
    exhibit_input_type: "FILE" | "TEXT"
    exhibit_name: string
    exhibit_text?: string
    files?: ExhibitsFileDTO[]
}

export interface ExhibitsFileDTO{
    file_type: string
    id: string
    name: string
    s3key: string
    size: number
}


export interface AssetRequest{
    case_filing_id: string
    item_id: string
}

export interface ExhibitRelations{
    id: string
    name: string
    related_item_ids: string[]
    status: string
}

export interface LetterSectionDTO{
    id: string
    order: number
    related_asset_id: string
    related_item_ids: string[]
    section_title: string
    subtitle: string
    criteria_type: string
    body: string
}

export interface PetitionLetterDTO{
    content: LetterSectionDTO[]
    can_update_petition_letter: boolean
    exhibit_items: {label: string, text: string}[]
    letter_status: LetterStatus
    letter_us_plan: string
    letter_introduction: string
}

export interface CriteriaDTO{
    criteria_description: string
    criteria_type: string
    selected: boolean
    supported: boolean
}

const asset = async (request: AssetRequest):Promise<AssetDTO> => {
    const response = (await axios.get(`/api/case_filing/${request.case_filing_id}/pl/items/${encodeURIComponent(request.item_id)}`)).data as AssetDTO;
    // checkCode(response)
    return response;
}

const analysisList = async (case_filling_id: string):Promise<any> => {
    const response = (await axios.get(`/api/case_filing/${case_filling_id}/pl`)).data
    // checkCode(response)
    return response;
}

const uploadExhibitFile = async (case_filling_id: string, exhibit_id: string, file_id: string):Promise<any> => {
    const response = (await axios.post(`/api/case_filing/${case_filling_id}/pl/${exhibit_id}/${file_id}`)).data
    return response;
}

const deleteExhibitFile = async (case_filling_id: string, exhibit_id: string, file_id: string):Promise<any> => {
    const response = (await axios.delete(`/api/case_filing/${case_filling_id}/pl/${exhibit_id}/${file_id}`)).data
    return response;
}

const getLetter = async (case_filling_id: string):Promise<PetitionLetterDTO> => {
    return new Promise(async (resolve, reject) => {
        try{
            const response = (await axios.get(`/api/case_filing/${case_filling_id}/pl/letter`)).data.data
            resolve(response)
        }catch(e){
            reject(e)
        }
        
    })
}

const updateLetter = async (case_filling_id: string, letter_format: string = "pdf"):Promise<any> => {
    const response = (await axios.post(`/api/case_filing/${case_filling_id}/pl/letter`)).data
    return response;
}

const exhibits = async (case_filling_id: string):Promise<ExhibitRelations[]> => {
    const response = (await axios.get(`/api/case_filing/${case_filling_id}/pl/items`)).data
    // checkCode(response)
    return response;
}

const updateExhibitText = async (case_filling_id: string, exhibit_id: string, text: string):Promise<any> => {
    const response = (await axios.post(`/api/case_filing/${case_filling_id}/pl/${exhibit_id}/text`, {text})).data
    return response;
}

const download = async (case_filling_id: string, letter_format: string = "pdf"):Promise<any> => {
    const response = (await axios.get(`/api/case_filing/${case_filling_id}/pl/letter/download?letter_format=${letter_format}`)).data.data
    return response;
}

const send2Client = async (case_filling_id: string, request: {exhibit_ids: string[], recipient_email: string}):Promise<any> => {
    const response = (await axios.post(`/api/case_filing/${case_filling_id}/pl/form`, request)).data.data
    return response;
}

const getCriteria = async (case_filling_id: string): Promise<CriteriaDTO[]> => {
    const response = (await axios.get(`/api/case_filing/${case_filling_id}/criteria`)).data.data
    return response;
}

const updateCriteria = async (case_filling_id: string, selected_criteria: string[]): Promise<CriteriaDTO[]> => {
    const response = (await axios.patch(`/api/case_filing/${case_filling_id}/criteria`, {selected_criteria})).data.data
    return response;
}



export default {
    asset,
    analysisList,
    uploadExhibitFile,
    deleteExhibitFile,
    getLetter,
    exhibits,
    updateExhibitText,
    download,
    updateLetter,
    send2Client,
    getCriteria,
    updateCriteria
}