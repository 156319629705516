import { Flex } from "antd"
import { StepForm } from "../CaseFillingForm"
import AddLink from "../components/AddLink"
import Form from "../../components/form/Form"
import { useFormInstance } from "../../components/form/FormContext"
import { ReactNode, useContext, useEffect, useState } from "react"
import { ClientCaseContext } from "../../clientmanagement/context/ClientCaseContext"
import { CaseFilingSteps, CaseFillingFormContext } from "../context/CaseFillingFormContext"
import Env from "../../../Env"
import LoadingIcon from "../../components/icon/LoadingIcon"
import CaseFillingApi from "../../api/CaseFilling.api"
import Icon from "../../components/icon/Icon"
import trashPrimarySvg from "../assets/images/trash-primary.svg";
import FormValidators from "../../components/form/FormValidators"
import PublicationsGraphsqlApi, { PublicationDTO } from "../../api/casefiling/PublicationsGraphsql.api"
import dayjs from "dayjs"
import { CaseFilingNotificationContext } from "../context/CaseFilingNotificationContext"
import styles from "./Publications.module.scss"
import { isNotEmpty } from "./AcademicBackground"
import validates from "../../libs/validates"

const publicationTypes = ["Journal", "Conference", "Chapter Book"]

const PublicationOptions = publicationTypes.map(it => {return {label: it, value: it.toLowerCase()}})


export default () => {

    return (
        <StepForm
            title="Publications"
            subtitle="Please review the publication information extracted by our data engine and add any missing details. Ensure that all publication information is included for comprehensive evidence collection."
        >
            <Publication/>
        </StepForm>
    )
}

interface PublicationIdx{
    id: string
    create?: boolean
    delete?: boolean
    form: Record<string, any>
    valid?: number
    btnValid?: boolean
    
}

const Publication = () => {

    const formInstance = useFormInstance({})
    const {clientCase} = useContext(ClientCaseContext)
    const {record, update, setStep, isFinish, setDisable} = useContext(CaseFillingFormContext)
    const [loading, setLoading] = useState<boolean>(true)
    const [publicationIdx, setPublicationIdx] = useState<PublicationIdx[]>([])
    const {publicationExtractionSucceeded, cleanPublicationExtractionSucceeded, setBroadcastMessage} = useContext(CaseFilingNotificationContext)
    // const [checkValid, setCheckValid] = useState<boolean>()

    // useEffect(() => {
    //     setBroadcastMessage({type: 'clean'})
    // }, [])

    useEffect(() => {
        if(record){
            if(record.publication_extraction_status === "SUCCEEDED"){
                PublicationsGraphsqlApi.publications(record.id).then(publications => {
                    Env.DEBUG && console.log("publications: ", publications)
                    
                    setPublicationIdx(publications.map(it => {
                        // const publicationsFormdata = publicationsConvertToFormData([it])
                        return {
                            id: it.id,
                            create: false,
                            form: it,
                        }
                    }))
                    // formInstance.setInitial({...publicationsFormdata})
                    // initialValidate(publications)
                    setLoading(false)
                })
            }else{
                setLoading(false)
                setBroadcastMessage({
                    type: "warn",
                    text: "We are currently processing the information from your resume. Please wait patiently."
                })
            }
            
        }
    }, [])


    useEffect(() => {

        if(publicationExtractionSucceeded && record){
            if(record.publication_extraction_status !== "SUCCEEDED"){
                CaseFillingApi.detail(record.case_id, record.id).then(update)
                cleanPublicationExtractionSucceeded()
                setBroadcastMessage({type: "clean"})
                record && PublicationsGraphsqlApi.publications(record.id).then(publications => {
                    // Env.DEBUG && console.log("publications: ", publications)
                    setPublicationIdx(publications.map(it => {
                        // const publicationsFormdata = publicationsConvertToFormData([it])
                        return {
                            id: it.id,
                            create: false,
                            form: it,
                        }
                    }))
                    // initialValidate(publications)
                })
            }
        }

    }, [publicationExtractionSucceeded])

    useEffect(() => {
        const prepareReduces = publicationIdx.filter(it => !it.create && it.valid).map(it => it.valid as number)
        console.log("publicationIdx changed: ", publicationIdx, " ", prepareReduces)
        if(prepareReduces.length == 0) return;
        const validScore = prepareReduces.reduce((o1, o2) => Math.min(o1 as number, o2 as number))
        if(validScore < 0){
            setDisable(CaseFilingSteps.Publications, true)
            record && 
            isFinish(CaseFilingSteps.Publications) && 
            CaseFillingApi.updateStatus(record.case_id, record.id, "PUBLICATIONS", false).then(() => {
                CaseFillingApi.detail(record.case_id, record.id).then(update)
            })
        }else{
            setDisable(CaseFilingSteps.Publications, false)
        }
    }, [publicationIdx])


    const doSubmit = async () => {
        let form: Record<string, any> = {}
        publicationIdx.forEach(it => {
            form = {...form, ...it.form}
        })
        // Env.DEBUG && console.log("Do submit, form: ", form);
        // const result = formDataConvertToPublications(form)
        // Env.DEBUG && console.log("submit plan: ", result)
        const taskId = record?.id as string 
        const caseId = record?.case_id as string
        // await PublicationsGraphsqlApi.updatePublications(taskId, result)
        return CaseFillingApi.updateStatus(caseId, taskId, "PUBLICATIONS").then(res => {
            CaseFillingApi.detail(caseId, taskId).then(update)
            setStep(CaseFilingSteps.Awards)
            setDisable(CaseFilingSteps.Publications, false)
        })
    }

    const addForm = () => {
        setPublicationIdx(current => {
            return [
                ...current,
                {
                    id: new Date().getTime() + "",
                    create: true,
                    form: {},
                }
            ]
        })
    }

    const removeForm = (id: string) => {
        setPublicationIdx(current => {
            const _current = current.map(it => {
                if(it.id === id){
                    return {
                        ...it, 
                        delete: true
                    }
                }
                return it
            }).filter(it => !(it.delete)) as PublicationIdx[]
            // Env.DEBUG && console.log("removeForm, current: ", _current)
            return _current
        })
    }

    const createForm = (id: string, record: Record<string, any>) => {
        setPublicationIdx(current => {
            const _current = current.map(it => {
                if(it.id === id){
                    return {
                        ...it, 
                        form: {...record},
                        create: false,
                        id: record['id']
                    }
                }
                return it
            })
            return _current
        })
    }

    const updateForm = (id: string, record: Record<string, any>) => {
        setPublicationIdx(current => {
            const _current = current.map(it => {
                if(it.id === id){
                    return {
                        ...it, 
                        form: {...record},
                    }
                }
                return it
            })
            return _current
        })
    }

    const validateForm = (id: string, valid: number) => {
        setPublicationIdx(current => {
            const _current = current.map(it => {
                if(it.id === id){
                    return {
                        ...it, 
                        valid
                    }
                }
                return it
            })
            return _current
        })
    }

    const validateBtn = (id: string, btnValid: boolean) => {
        setPublicationIdx(current => {
            const _current = current.map(it => {
                if(it.id === id){
                    return {
                        ...it, 
                        btnValid
                    }
                }
                return it
            })
            return _current
        })
    }

    const publicationsFormRender = () => {

        const nodes: ReactNode[] = []
        for(let i = 0; i < publicationIdx.length; i++){
            const idx = publicationIdx[i];
            if(idx.delete) continue;
            const id = idx.id;
            nodes.push(
                <PublicationsForm 
                    key={id} 
                    id={id} 
                    onRemove={() => removeForm(id)}
                    onUpdate={(record) => updateForm(id, record)}
                    onValidate={(valid) => validateForm(id, valid)}
                    record={idx.form}
                    autoValidate={!idx.create}
                    onBtnValidate={(valid) => validateBtn(id, valid)}
                    onCreated={(record) => createForm(id, record)}
                    />
            )
        }
        return <>{nodes}</>

    }

    const valid = () => {
        const forValidateIdx = publicationIdx.filter(it => !it.delete)
        if(!forValidateIdx || forValidateIdx.length == 0) return true
        return !forValidateIdx.find(it => !it.btnValid)
    }

    if(loading){
        return (
            <LoadingIcon/>
        )
    }

    return (

        <Flex vertical gap={48}>
            {
                record?.publication_extraction_status === "SUBMITTED"
                ?
                <div className={styles['notification_analysing']}>
                    We are currently processing information from your resume. Please wait patiently.
                </div>
                :
                <Flex vertical gap={16}>
                    <Flex vertical gap={32}>
                        {publicationsFormRender()}
                    </Flex>
                    {!isFinish(CaseFilingSteps.ReviewAndSubmit) && <AddLink onClick={addForm}>Add more publications</AddLink>}
                </Flex>
            }
            <Flex justify="flex-end">
                <Form.Form
                    instance={formInstance}
                    onSubmit={doSubmit}
                >
                    <Form.Submit width={185} disabled={!valid() || isFinish(CaseFilingSteps.ReviewAndSubmit)} >Continue</Form.Submit>
                </Form.Form>
            </Flex>
        </Flex>
        
        
    )

}

const PublicationsForm = (
    props: {
        id: string, 
        onRemove: () => void, 
        record: Record<string, any>, 
        onUpdate: (updated: Record<string, any>, oldId?: string) => void, 
        onValidate: (valid: number) => void
        onBtnValidate: (valid: boolean) => void
        autoValidate: boolean
        onCreated: (updated: Record<string, any>) => void
    }
) => {

    const {update, setStep, isFinish, record: task} = useContext(CaseFillingFormContext)
    const formInstance = useFormInstance({autoValidate: false})
    const {
        id,
        record,
        onRemove,
        onUpdate,
        onValidate,
        onBtnValidate,
        autoValidate,
        onCreated
    } = props

    const {
        values2,
        errors2,
        disabled
    } = formInstance
    const [recordId, setRecordId] = useState<string>()

    useEffect(() => {
        record && setRecordId(record.id)
        autoValidate && formInstance.setInitial(record) //TODO auto validate
    }, [])

    useEffect(() => {
        if(values2){
            if(validates.isDeepEqual(values2, props.record)){
                console.log("值未发生变化。。。")
                return;
            }
            if(task){
                if(!recordId && isNotEmpty(values2)){
                    PublicationsGraphsqlApi.createPublication(task?.id, values2 as PublicationDTO).then(it => {
                        setRecordId(it.id)
                        onCreated(it)
                    })
                }else if(recordId){
                    PublicationsGraphsqlApi.editPublication({...values2, id: recordId} as PublicationDTO).then(() => {
                        onUpdate(values2)
                    })
                    
                }
            }
            onUpdate(values2)
        }
    }, [values2])

    useEffect(() => {
        if(errors2){
            onValidate(validates.isEmpty(errors2)?1:-1) 
        }
    }, [errors2])

    useEffect(() => {
        onBtnValidate(!disabled)
    }, [disabled])

    const doRemove = () => {
        recordId && PublicationsGraphsqlApi.deletePublication(recordId)
        onRemove()
    }


    return (
        <Form.Form instance={formInstance}>
            <Flex gap={16} vertical key={id}>
            { !isFinish(CaseFilingSteps.ReviewAndSubmit) && <Flex justify="flex-end">
                    <Icon src={trashPrimarySvg} size={24} onClick={() => doRemove()}></Icon>
                </Flex>}
                {/* <Form.Item
                    label="Type"
                    name={`type`}
                    required
                    hiddenError
                    rules={[{validate: FormValidators.required, msg: " "}]}
                >
                    <Form.Select placeholder="Select one" options={PublicationOptions} readOnly={isFinish(CaseFilingSteps.ReviewAndSubmit)}/>
                </Form.Item>             */}

                <Form.Item
                    label="Title"
                    name={`title`}
                    required
                    hiddenError
                    rules={[{validate: FormValidators.required, msg: " "}]}
                >
                    <Form.Input placeholder="Please enter the title" readOnly={isFinish(CaseFilingSteps.ReviewAndSubmit)}/>
                </Form.Item>     

                <Form.Item
                    label="Author ranking"
                    name={`authorRanking`}
                    hiddenError
                >
                    <Form.Input placeholder="Ex. 1" readOnly={isFinish(CaseFilingSteps.ReviewAndSubmit)}/>
                </Form.Item>     

                <Form.Item
                    label="Journal/Conference name"
                    name={`journalConferenceName`}
                    required
                    hiddenError
                    rules={[{validate: FormValidators.required, msg: " "}]}
                >
                    <Form.Input placeholder="Please enter the name" readOnly={isFinish(CaseFilingSteps.ReviewAndSubmit)}/>
                </Form.Item>     

                {/* <Form.Item
                    label="Year"
                    name={`year`}
                    required
                    hiddenError
                    rules={[{validate: FormValidators.required, msg: " "}]}
                >
                    <Form.DatePicker minDate={dayjs('1900')} picker="year" placeholder="Select one" readOnly={isFinish(CaseFilingSteps.ReviewAndSubmit)}/>
                </Form.Item>   */}
            </Flex>
        </Form.Form>
        
    )

}
