
import { useContext, useEffect, useState } from "react";
import styles from "./QuickEvalution.module.scss";
import { ClientCaseContext } from "../context/ClientCaseContext";
import { Col, Flex, Row } from "antd";
import Button from "../../components/Button";
import useBroadcast from "../../components/broadcast/useBroadcast";
import { ChatScreenContext } from "../context/ChatScreenContext";
import { useFormInstance } from "../../components/form/FormContext";
import Env from "../../../Env";
import ClientApi, { CaseDTO, QuickEvaluationMetadata } from "../../api/Client.api";
import Form from "../../components/form/Form";
import FormValidators from "../../components/form/FormValidators";
import ChatApi from "../../api/Chat.api";

const systemText = "Please furnish us with the nationality and visa type information of your client. Upon receiving this, we will be able to automatically update the priority date for their case. Additionally, with their permission, we will send email updates to the provided email address regarding their case status."


export default () => {

    const {quickEvaluation} = useContext(ClientCaseContext)
    const {pin, setLoading} = useContext(ChatScreenContext)
    const [next, setNext] = useState<boolean>()
    const {error} = useBroadcast()
    
    useEffect(() => {
        Env.DEBUG && console.log("quickEvaluation: ", quickEvaluation)
        if(!quickEvaluation){
            pin({
                author: "system", 
                payload: systemText, 
                type: "text"
            }, true)
        }else{
            setNext(true)
            setLoading(true)
            ChatApi.messages({chat_session_id: quickEvaluation.chat_session_id}).then(({messages}) => {
                pin(messages, true);
                setLoading(false);
            }).catch((e) => {
                error(e); 
                setLoading(false)
            })
        }
    }, [quickEvaluation])

    const Welcome = () => {
        return (
            <Flex vertical gap={32} className={styles["quick-evaluation__welcome"]}>
                <div>Quick Case Evaluation involves entering their details to compare against successful cases in a database, thereby providing a preliminary ranking of their EB1/NIW eligibility.</div>
                <Button.Primary width={160} style={{'alignSelf': 'flex-end'}} onClick={() => setNext(true)}>Start</Button.Primary>
            </Flex>
        )
    }

    return (
        <div className={styles['quick-evaluation']}>
            {
                next
                ?
                <QuickEvaluationForm/>
                :
                <Welcome/>
            }
        </div>
    )

}

const QuickEvaluationForm = () => {

    const {pin} = useContext(ChatScreenContext)
    const formInstance = useFormInstance()
    const {
        values
    } = formInstance
    const {clientCase, quickEvaluation, setQuickEvaluation} = useContext(ClientCaseContext)
    const {error} = useBroadcast()

    useEffect(() => {
        Env.DEBUG && console.log("quickEvaluation changed:", quickEvaluation);
        formInstance.setInitial(quickEvaluation?.metadata);
    }, [quickEvaluation])

    useEffect(() => {
        // // console.log("value changed:", formInstance.values)
        // const formName = formInstance.values?.['form_name']
        // const formCategory = formInstance.values?.['form_category']
        // const serviceCenter = formInstance.values?.['service_center']
        // if(!formName){
        //     setFormCategoryOptions([])
        //     setFormServiceCenterOptions([])
        // }else{
        //     setFormCategoryOptions(Options.formCategory(formName))
        //     if(!formCategory){
        //         setFormServiceCenterOptions([])
        //     }else{
        //         setFormServiceCenterOptions(Options.formCenterService(formName, formCategory))
        //     }
        // }
    }, [])

    const fieldOptions = [
        {
            label: "Medicine",
            value: "Medicine"
        },
        {
            label: "Chemistry",
            value: "Chemistry"
        },
        {
            label: "Engineering and Technology",
            value: "Engineering and Technology"
        },
        {
            label: "Biology and Biochemistry",
            value: "Biology and Biochemistry"
        },
        {
            label: "Computer Science",
            value: "Computer Science"
        },
        {
            label: "Genetics and molecular Biology",
            value: "Genetics and molecular Biology"
        },
        {
            label: "Electronics and Electrical Engineering",
            value: "Electronics and Electrical Engineering"
        },
        {
            label: "Materials Science",
            value: "Materials Science"
        },
        {
            label: "Physics",
            value: "Physics"
        },
        {
            label: "Mechanical and Aerospace Engineering",
            value: "Mechanical and Aerospace Engineering"
        },
        {
            label: "Mathematics",
            value: "Mathematics"
        },
        {
            label: "Environmental Sciences",
            value: "Environmental Sciences"
        },
        {
            label: "Earth Science",
            value: "Earth Science"
        },
        {
            label: "Social Sciences and Humanity",
            value: "Social Sciences and Humanity"
        }
    ]

    const degreeOptions = [
        {
            label: "PhD",
            value: "PhD"
        },
        {
            label: "Master",
            value: "Master"
        },
        {
            label: "Undergraduate",
            value: "Undergraduate"
        }
    ]


    const doSubmit = (form: QuickEvaluationMetadata) => {
        return ClientApi
            .updateQuickEvaluation((clientCase as CaseDTO).case_id, form)
            .then(({messages}) => {
                Env.DEBUG && console.log("updated quick evaluation, messages: ", messages);
                pin(messages);
                // ChatScreenApi
                // .messages({chat_session_id: (checkStatus as CaseTool).chat_session_id})
                // .then((message) => {
                //     Env.DEBUG && console.log("receive check status messages: ", message)
                // })
            }).catch((e) => error(e))
    }

    return (
        <Form.Form
            instance={formInstance}
            onSubmit={doSubmit}
            showError={false}
        >
            <Flex vertical gap={0}>
                
                <Row gutter={[0, 16]}>
                    <Col span={24}>
                        <Flex align="center" className={styles['form-group__title']}>Field of application<span className={styles['form-group__title__required']}>*</span></Flex>
                    </Col>
                    <Col span={24}>
                        <Form.Item 
                            name="study_field" 
                            label="Field of application"
                            required
                            rules={[{validate: FormValidators.required, msg: "Form"}]}
                        >
                            <Form.Select placeholder="Select One" options={fieldOptions}></Form.Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[0, 16]}>
                    <Col span={24}>
                        <Flex align="center" className={styles['form-group__title']}>Academic background<span className={styles['form-group__title__required']}>*</span></Flex>
                    </Col>
                    <Col span={24}>
                        <Form.Item 
                            name="highest_degree" 
                            label="Highest academic degree"
                            required
                            rules={[{validate: FormValidators.required, msg: "Form"}]}
                        >
                            <Form.Select placeholder="Select One" options={degreeOptions}></Form.Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[0, 16]}>
                    <Col span={24}>
                        <Flex align="center" className={styles['form-group__title']}>Professional achievements</Flex>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="paper_amount" 
                            label="Number of published papers"
                        >
                            <Form.Input placeholder="Ex. 15" />
                        </Form.Item>
                        <Form.Item 
                            name="citation_amount" 
                            label="Total citations received"
                        >
                            <Form.Input placeholder="Ex. 15" />
                        </Form.Item>
                        <Form.Item 
                            name="review_amount" 
                            label="Number of peer reviews"
                        >
                            <Form.Input placeholder="Ex. 15" />
                        </Form.Item>
                        <Form.Item 
                            name="patent_amount" 
                            label="Number of patents filed/granted"
                        >
                            <Form.Input placeholder="Ex. 15" />
                        </Form.Item>
                        <Form.Item 
                            name="award_amount" 
                            label="Number of awards and honors (National level or above)"
                        >
                            <Form.Input placeholder="Ex. 15" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[0, 16]}>
                    <Flex align="center" className={styles['form-group__title']}>Additional information</Flex>
                    <Col span={24}>
                        <Form.Item 
                            name="additional_information" 
                            label="Additional information (Optional)"
                        >
                            <Form.TextArea placeholder="Please list any other details you know about this applicant." />
                        </Form.Item>
                    </Col>
                </Row>

                <Flex justify="flex-end" gap={12}>
                    <Button.TransparentBg width={128}>Cancel</Button.TransparentBg>
                    <Form.Submit width={128}>Update</Form.Submit>
                </Flex>
            </Flex>

        </Form.Form>
    )

}